import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { MatTableModule } from '@angular/material/table';
import { HttpClientModule } from '@angular/common/http';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DelegationOfAuthorityService } from 'src/app/services/doa/delegationOfAuthority.service';
import { CalAngularService } from '@cvx/cal-angular';
import { IReferenceData } from 'src/app/interfaces/admin/referencedata';
import { ConfirmationDialogComponent } from './confirmdialog/confirmdialog.component';
import { CommonService } from 'src/app/services/common/common.service';
@Component({
  selector: 'Temporary-Doa-Designee',
  templateUrl: 'temporary-doa-designee.component.html',
  styleUrls: ['temporary-doa-designee.component.css'],
  imports: [MatTableModule]
})

export class TemporaryDoaDesigneeComponent implements OnInit {
  title = 'Temporary DOA Designees';
  searchEmail: string;
  modalMessage: string;
  showElement: boolean = true;
  tempDoaDesignee = {} as any;
  dataSource = new MatTableDataSource<any>();
  searchDataSource = new MatTableDataSource<any>();

  public referenceData: IReferenceData;

  dialogRef: MatDialogRef<ConfirmationDialogComponent>;

  currentBU: string = '';



  constructor(private tempdoadesigneeservice: DelegationOfAuthorityService, public dialog: MatDialog,
    private authService: CalAngularService, private changeDetectorRef: ChangeDetectorRef,private commonService : CommonService
  ) { }

  displayedColumns: string[] = ['name', 'email', 'department', 'jobTitle', 'Option'];

  ngOnInit(): void {
    if (this.authService.isUserSignedIn()) {
      var storedData = this.commonService.getReferenceData();
      if (storedData) {
        this.referenceData = storedData;
        this.currentBU = this.commonService.getCurrentBusinessUnit();
        if (this.currentBU == null) {
          this.currentBU = "SOUTHERN AFRICA SBU";
        }
        this.loadGridData();
      }

    }
  }
  loadGridData() {
    this.tempdoadesigneeservice.getTempDOADesignees(this.authService.cvxClaimsPrincipal.email, this.currentBU).subscribe((refreshdata: any) => {
      this.dataSource.data = refreshdata;
      this.dataSource._updateChangeSubscription();
      this.changeDetectorRef.detectChanges();

    });
  }

  searchUser() {

    this.tempdoadesigneeservice.getDelegationOfAuthority(this.searchEmail).subscribe((searchData: any) => {

      if (searchData) {
        this.searchDataSource.data = [];
        this.searchDataSource.data.push(searchData);
        this.searchDataSource._updateChangeSubscription();
      }
      else {
        this.showSuccessModal("Employee information not Found");
      }

    });
  }


  openRemoveDialog(element: any): void {
    this.dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      disableClose: false, height: '150px', width: '500px'
    });
    this.dialogRef.componentInstance.confirmMessage = "Are you sure you want to delete selected designee permanently?"

    this.dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // do confirmation actions
        this.deleteSelectedDesignee(element);
        this.showSuccessModal("Designee removed successfully");
      }
      this.dialogRef = null;
    }
    )
  };
  deleteSelectedDesignee(element: any): void {
    this.tempdoadesigneeservice.deleteTempDOADesignee(element.id, this.currentBU).subscribe((mydata: any) => {
      if (mydata) {
        this.loadGridData();
      }
    });

  }


  showSuccessModal(msg: string): void {
    this.modalMessage = msg;
    const modal = document.getElementById('successModal') as HTMLElement;
    const okBtn = document.getElementById('closeSuccessModal') as HTMLElement;

    modal.style.display = 'block';

    okBtn.onclick = () => {
      modal.style.display = 'none';
    };

    window.onclick = (event) => {
      if (event.target === modal) {
        modal.style.display = 'none';
      }
    };
  }


  addObject(element: any) {
    const exists = this.dataSource.data.some((item: any) => {
      return item.designee.toLowerCase() == element.emailAddress.toLowerCase();
    });

    if (exists) {
      this.showSuccessModal("Designee already exists");
    } else {
      if (this.dataSource.data.length < 3) {
        if (element.hasAdminRole == true) {
          this.showSuccessModal("You cannot add Administrator as designee.");
        }
        else {
          this.tempDoaDesignee = { id: 0, designee: element.emailAddress, email: this.authService.cvxClaimsPrincipal.email, name: element.name, department: element?.department != null ? element.department : '', jobTitle: element?.jobTitle != null ? element.jobTitle : '', loggedInUserEmail: this.authService.cvxClaimsPrincipal.email };
          this.tempdoadesigneeservice.addTempDOADesignee(this.tempDoaDesignee, this.currentBU).subscribe((mydata: any) => {
            if (mydata) {
              this.loadGridData();
              if (this.dataSource.data.length === 2) {
                this.showSuccessModal("Designee added succesfully. You have already added maximum number of designees");
              } else {
                this.showSuccessModal("Designee added successfully.");
              }
            }
          });
          //this.dataSource._updateChangeSubscription();
          this.clearSearchResults();
        }


      } else {
        // Show popup message for maximum number of designees
        this.showSuccessModal("You have already added the maximum number of designees.");
      }
    }
  }

  clearSearchResults() {
    this.searchDataSource.data = [];
    this.searchEmail = "";
  }
}
