import { SelectionModel } from '@angular/cdk/collections';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatCheckboxChange, MatCheckboxModule } from '@angular/material/checkbox';
import { MatTable, MatTableDataSource, MatTableModule } from '@angular/material/table';
import { CalAngularService } from '@cvx/cal-angular';
import { map } from 'rxjs';
import { IReferenceData } from 'src/app/interfaces/admin/referencedata';
import { ReportserviceService } from 'src/app/services/reportservice.service';
import { CommonService } from '../../services/common/common.service';
import { Router } from '@angular/router';
import { ReviewDialogComponent } from 'src/app/doa/permanent-doa/review-dialog/review-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { IMyDelegations } from 'src/app/interfaces/reports/mydelegations';
import { DelegationOfAuthorityService } from 'src/app/services/doa/delegationOfAuthority.service';
 
 
@Component({
  selector: 'mydelegation',
  templateUrl: 'mydelegation.component.html',
  styleUrls: ["mydelegation.component.css"],
  imports: [MatTableModule, MatCheckboxModule,MatPaginatorModule]
})
 
export class MyDelegationComponent implements OnInit {
  title = "My Delegation Report";
  displayedColumns: string[] = [ 'select','empName', 'empEmail', 'doaType', 'amount', 'effectiveDate', 'statusCodeId', 'grantorEmail', 'supervisorEmail', 'changeOrUpdate'];
  approveColumns : string[] =  [ 'empName', 'empEmail', 'doaType', 'amount', 'effectiveDate', 'status', 'grantorEmail', 'supervisorEmail', 'changeOrUpdate'];
  dataSource = new MatTableDataSource<IMyDelegations>();
  grantorEmail: string;
  statusCodeId: number = 1;
  currentBU: string = '';
  referenceData: IReferenceData;
  reason : string;
  ApprovedataSource = new MatTableDataSource<any>();
  selection = new SelectionModel<any>(true, []);
  permanentrequestId: number;
  modalMessage : string;
  @ViewChild('paginator1') paginator1: MatPaginator;
  @ViewChild("paginator2") paginator2: MatPaginator;
  loggedInUserEmail= this.authService.cvxClaimsPrincipal.email;
  data: any;
  loadingPermanentdoa: boolean = false;
  loadingdoamaster:boolean=false;
  isAuditor:boolean;
  isAdmin:boolean;
  employeeData:any;
 
 
 
 
  constructor(
    private _reportserviceService: ReportserviceService,
    private authService: CalAngularService,
    private commonService: CommonService,
    private changeDetectorRef: ChangeDetectorRef,
    private router: Router,
    private dialog: MatDialog,
    private permanentdoaService:DelegationOfAuthorityService
 
  ) {this.dataSource=new MatTableDataSource<IMyDelegations>() }
 
  ngOnInit(): void {
    this.grantorEmail = this.authService.cvxClaimsPrincipal.email;
    this.commonService.isRoleAdmin().subscribe((response) => {
      this.isAdmin=response;
    });
    this.commonService.isRoleAuditor().subscribe((response) => {
      this.isAuditor = response;
    });
    if (this.authService.isUserSignedIn()) {
      const storedData = this.commonService.getReferenceData();
      if (storedData) {
        this.referenceData = storedData;
        this.currentBU = this.commonService.getCurrentBusinessUnit();
        this.loadGridData();
      }
    }
  }
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator1;
this.ApprovedataSource.paginator=this.paginator2;
  }
 
  loadGridData(): void {
    this.loadingPermanentdoa = true;
    this.loadingdoamaster = true;
    this._reportserviceService.GetPermanentDOARequestsByGrantorEmailAndStatus(this.grantorEmail, this.statusCodeId, this.currentBU).subscribe((refreshdata: any) => {
      refreshdata.forEach((outer) => {
        const e = this.referenceData.doaTypeList.filter((inner) => outer.id === inner.id);
        if(e!=null && e[0]!=null){
          outer.typeName = e[0].typeName;
        }
        outer.loggedInUserEmail= this.authService.cvxClaimsPrincipal.email;
        outer.businessUnit=this.currentBU;
      });
      this.loadingPermanentdoa = false;
     
      this.dataSource.data = refreshdata;
      this.dataSource = new MatTableDataSource(this.dataSource.data);
      this.dataSource.paginator=this.paginator1;
      this.dataSource._updateChangeSubscription();
      this.changeDetectorRef.detectChanges();
    });
 
    this._reportserviceService.getMasterData(this.currentBU, this.grantorEmail).subscribe((refreshdata: any) => {
      this.data = refreshdata;
      this.loadingdoamaster = false;
   
      this.data.forEach((item) => {
          this.permanentdoaService.getCategoryDOA(item.empEmail).subscribe((refreshdata:any) => {
            this.employeeData = refreshdata.empoyeeInfo.name;
            let type = this.referenceData.doaTypeList.find((u) => u.id === item.typeCodeId && u.categoryId === item.catCodeId);
            item.doaType = type ? type.typeName : 'Unknown';
            item.empName=this.employeeData
          });
      });
      this.loadingdoamaster = false;
      this.ApprovedataSource.data = this.data;
      this.ApprovedataSource = new MatTableDataSource(this.ApprovedataSource.data);
      this.ApprovedataSource.paginator = this.paginator2;
      this.ApprovedataSource._updateChangeSubscription();
      this.changeDetectorRef.detectChanges();
    });
  }    
 
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }
 
 
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }
  viewDOA(row){
    var param = {
      requestId: row.id,
      typeCode: 0,
      empCAI: null,
      action: 'Approve'
     }
     this.commonService.params=param;
     this.router.navigate(['/DOA/PermanentDOA']);
   }
 
 
 
   BulkAprovePermanentDOA() {
    if (this.selection.selected.length === 0) {
      // Show popup if no items are selected
      this.showSuccessModal('Please select requests to be approved using check box');
      return;
  }
    //console.log(this.selection.selected);
   this._reportserviceService.BulkAprovePermanentDOA(this.selection.selected).subscribe(response => {
       console.log('Bulk approve successful', response);
       this.showSuccessModal('Authority lines approved successfully');
       // Optionally, refresh the data or show a success message
       this.loadGridData();
     }, error => {
       console.error('Bulk approve failed', error);
       // Optionally, show an error message
     });
   }
   viewApprovedDOA(row){
    var param = {
      requestId: 0,
      typeCode: row.typeCodeId,
      empEmail: row.empEmail,
      action: 'Reconcile'
     }
     this.commonService.params=param;
     this.router.navigate(['/DOA/PermanentDOA']);
   }
   
   
   BulkRejectPermanentDOAReq() {
    if (this.selection.selected.length === 0) {
      // Show popup if no items are selected
      this.showSuccessModal('Please select requests to be approved using check box');
      return;
  }
    const dialogRef = this.dialog.open(ReviewDialogComponent, {
      width: '250px',
      disableClose: true,
    });
 
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed with review:', result);
      this.reason = result;
      if (this.reason) {
        this.selection.selected.forEach(item => {
          item.rejectionReason = this.reason;
          item.businessUnit = this.currentBU;
        });
      }
 
      // const selectedIds = this.selection.selected.map(item => item.id);
      this._reportserviceService.BulkRejectPermanentDOAReq(this.selection.selected).subscribe(response => {
        console.log('Bulk reject successful', response);
        this.showSuccessModal('Authority lines rejected successfully')
        // Optionally, refresh the data or show a success message
        this.loadGridData();
      }, error => {
        console.error('Bulk reject failed', error);
        // Optionally, show an error message
      });
    });
  }
  showSuccessModal(msg: string): void {
    this.modalMessage = msg;
    const modal = document.getElementById('successModal') as HTMLElement;
    const okBtn = document.getElementById('closeSuccessModal') as HTMLElement;
 
    modal.style.display = 'block';
 
    okBtn.onclick = () => {
      modal.style.display = 'none';
      this.loadGridData();
    };
 
    window.onclick = (event) => {
      if (event.target === modal) {
        modal.style.display = 'none';
        this.loadGridData();
      }
    };
  }
  applyFilter(filterValue: string, column: string) {
    this.dataSource.filterPredicate = (data: IMyDelegations, filter: string) => {
        const transformedFilter = JSON.parse(filter);
        const dataValue = data[column] ? String(data[column]).toLowerCase() : '';
        return dataValue.includes(transformedFilter[column].toLowerCase());
    };
    const filterObject = { [column]: filterValue };
    this.dataSource.filter = JSON.stringify(filterObject);
  }
  applyFilter2(filterValue: string, column: string) {
    this.ApprovedataSource.filterPredicate = (data: IMyDelegations, filter: string) => {
        const transformedFilter = JSON.parse(filter);
        const dataValue = data[column] ? String(data[column]).toLowerCase() : '';
        return dataValue.includes(transformedFilter[column].toLowerCase());
    };
    const filterObject = { [column]: filterValue };
    this.ApprovedataSource.filter = JSON.stringify(filterObject);
  }
}