export const environment = {
  production: false,
  groupName: "--",
  BusinessUnit: "SOUTHERN AFRICA SBU",
  apiUrl: "https://doa-sasbu-api-test.azure.chevron.com/api",
  scope:"https://doa-sasbu-test.azure.chevron.com/user_impersonation",
  histroyReportUrl: "https://app.powerbi.com/reportEmbed?reportId=315726c7-6a00-413b-8f0a-0fb1225fadba&autoAuth=true&ctid=fd799da1-bfc1-4234-a91c-72b3a1cb9e26",
  logReportUrl:"https://app.powerbi.com/reportEmbed?reportId=1ffe85ce-c7e6-4497-891e-d1ca39c27818&autoAuth=true&ctid=fd799da1-bfc1-4234-a91c-72b3a1cb9e26",
  integrityReportUrl: "https://app.powerbi.com/reportEmbed?reportId=4d4743bb-61ac-49a0-8f02-dbf45a42d178&autoAuth=true&ctid=fd799da1-bfc1-4234-a91c-72b3a1cb9e26",
  masterReportUrl:"https://app.powerbi.com/reportEmbed?reportId=d66074b1-9629-4632-a272-dc17bf1cc719&autoAuth=true&ctid=fd799da1-bfc1-4234-a91c-72b3a1cb9e26"



};
