import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableModule } from "@angular/material/table";
import { MatPaginator } from '@angular/material/paginator';
import { FormBuilder, FormGroup, Validators, FormArray, Form } from '@angular/forms';
import { AdminService } from '../../services/admin/admin.service';
import { RouterLink, Router } from '@angular/router';
import { SearchService } from 'src/app/services/search/search.service';
import { DelegationOfAuthorityService } from 'src/app/services/doa/delegationOfAuthority.service';
import { ISearchInput } from 'src/app/interfaces/search/searchinput';
import { UpdateGrantorAndAttachmentSubmitDTO } from 'src/app/interfaces/admin/updategrantor';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { Sort } from "@angular/material/sort";
import { TableSortService } from "../../services/table-sort.services";
import { CommonService } from 'src/app/services/common/common.service';

@Component({
  selector: 'app-updategrantor',
  templateUrl: './updategrantor.component.html',
  styleUrl: './updategrantor.component.css'
})
export class UpdategrantorComponent implements OnInit {
  title = "Update Grantor";
  email: any;
  dataSourceLoading: boolean = false;
  public searchInput: ISearchInput;
  searchForm: FormGroup;
  result: any = [];
  displayemail: any;
  employeeName: any;
  searchedEmployeeName: any;
  grantorName: any;
  emailSearched: boolean = false;
  UpdatedGrantorEmail?: any;
  selectedFile: File | null = null;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  isReadonly: boolean = true;
  listingsVisible: boolean = true; 
  fileAttachments: any[] = [];
  displayedColumns: string[] = ['empEmail', 'employeeName', 'supervisorEmail', 'grantorEmail', 'update'];
  data: any = [];
  filteredData: MatTableDataSource<any>;
  updatedRowObject: UpdateGrantorAndAttachmentSubmitDTO= { updatedRows : null, selectedFile:null };

  searchTerms: any = {
    empEmail: '',
    employeeName: '',
    supervisorEmail: '',
    grantorEmail: ''
  };
  grantorEmail: any = [];
  employeeEmail: any = [];
  businessUnit: string = '';
  emailAddress: string = '';

  constructor(private adminService: AdminService,
    private tableSortService: TableSortService, 
    private fb: FormBuilder,
    private doaService: DelegationOfAuthorityService,
    private searchDOADataService: SearchService, private router: Router,
    private commonService:CommonService) { }

  ngOnInit(): void {
  this.filteredData =  new MatTableDataSource(this.data);
  this.filteredData.paginator = this.paginator;
  this.filteredData.sort = this.sort;
    this.searchForm = this.fb.group({
      statusDesc: 'All',
      authorityLine: 'All',
      authorityLineCode: 0,
      department: '',
      grantorEmail: '',
      categoryName: 'All',
      categoryCode: 0,
      employeeEmail: ''
    });
  }
  enableInput() {
    this.isReadonly = false;
  }
  async onFileChange(event: any) {
    const file = event.target.files[0];
    if (file) {
      this.selectedFile = file;     
    }
  }
 
  onSearch(): void {
    this.emailSearched = true;
    this.dataSourceLoading = true;
    this.searchInput = this.searchForm.value;
    this.searchInput.grantorEmail = this.email;
    this.searchInput.businessUnit = this.commonService.getCurrentBusinessUnit();
    if (this.email) {
      this.listingsVisible = false;  
      this.searchDOADataService.getSearchPermanentDOA(this.searchInput).subscribe({
        next: (res: any) => {    
          if (res.length == 0) {
            this.dataSourceLoading = false;
            this.listingsVisible = false;   
            this.commonService.openModal('','Employee information is not found');
          }
          else{
          this.data = res;
          this.grantorName = [];
          this.searchedEmployeeName = this.data[0].grantorName;
          this.filteredData =  new MatTableDataSource(this.data);
          this.filteredData.paginator = this.paginator;
          this.filteredData.sort = this.sort;
          this.dataSourceLoading = false;
            this.adminService.getUpdateGrantorAttachmentsList(this.commonService.getCurrentBusinessUnit(), null, this.email, null).subscribe({
              next: (response) => {
                this.fileAttachments = response;
              },
              error: (error) => {
                console.error('Error fetching attachments:', error);
                this.commonService.openModal('','Failed to fetch attachments.');
              }
            });
          }
        },
        error: (error) => {
          this.commonService.openModal('','Failed to fetch data.');
          console.error('Error fetching data:', error);
          this.dataSourceLoading = false;
        }
      });

    } else {
      this.searchedEmployeeName=null;
      this.fileAttachments = null;
      this.commonService.openModal('','Employee details not found');
      //alert('Employee details not found');
      this.searchDOADataService.getSearchPermanentDOA(this.searchInput).subscribe({
        next: (res: any) => {
          this.data = res;
          this.grantorName = [];
          this.filteredData =  new MatTableDataSource(this.data);
          this.filteredData.paginator = this.paginator;
          this.filteredData.sort = this.sort;
          this.dataSourceLoading = false;
          this.listingsVisible = false;  
        },
        error: (error) => {
          console.error('Error fetching data:', error);
          this.commonService.openModal('','Failed to fetch data.');
          //alert('Failed to fetch data.');
          this.dataSourceLoading = false;
        }
      });
    }

   
  }
  applyFilter() {
    this.filteredData = this.data.filter((item: any) => {
      return Object.keys(this.searchTerms).every(key => {
        return item[key].toLowerCase().includes(this.searchTerms[key].toLowerCase());
      });
    });
  }
  checkforGrantorUpdates() {
    return this.data.some(item => item['update'] != null);
  }
  announceSortChange(event: Sort) {
    // this.filteredData = this.tableSortService.sortDataSource(
    //   this.data,
    //   event
    // );
  }
  
   
  private readFileAsByteArray(file: File): Promise<Uint8Array> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const arrayBuffer = reader.result as ArrayBuffer;
        const byteArray = new Uint8Array(arrayBuffer);
        resolve(byteArray);
      };
      reader.onerror = reject;
      reader.readAsArrayBuffer(file);
    });
  }
  onSubmit() {
    if (!this.selectedFile) {
      this.commonService.openModal('','Please attach Evidence related to this request.');
      return;
    }
    const updatedData = this.data.filter(item => item.update);
    if (updatedData.length === 0) {
      this.commonService.openModal('','Please update at least one Grantor Email.');
      return;
    }
   
    if (!this.commonService.getCurrentBusinessUnit() || !this.commonService.getLoggedInUserEmail()) {
      this.commonService.openModal('','Failed to retrieve employee information.');
      return;
    }
   
    this.updatedRowObject.updatedRows = updatedData;
    this.updatedRowObject.updatedRows.forEach(item => {
      item.businessUnit= this.commonService.getCurrentBusinessUnit();
      item.loggedInUserEmail = this.commonService.getLoggedInUserEmail();
  });
    this.updatedRowObject.selectedFile = this.selectedFile;
  
   
   
      this.adminService.updateGrantorDetails(this.selectedFile, this.updatedRowObject).subscribe({
        next: (response) => {       
        this.commonService.openModal(response.messageTitle,response.messageDesc);
        this.router.navigate(['Home']);       
        },
        error: (error) => {
          this.commonService.openModal(error.messageTitle,error.messageDesc);
          console.error('Upload failed', error);
          this.router.navigate(['Home']);
        }
      });
    
  }



  downloadFile(file: any) {
    if (file && file.fileData) {
      const fileBlob = new Blob([this.hexToBytes(file.fileData)], { type: 'application/octet-stream' });
      const downloadUrl = URL.createObjectURL(fileBlob);
      const anchor = document.createElement('a');
      anchor.href = downloadUrl;
      anchor.download = file.fileName || 'download';
      document.body.appendChild(anchor);
      anchor.click();

      document.body.removeChild(anchor);
      URL.revokeObjectURL(downloadUrl);
    } else {
      this.commonService.openModal('','File data is missing!');
    }
  }
  hexToBytes(hex: string) {
    const bytes = [];
    for (let i = 0; i < hex.length; i += 2) {
      bytes.push(parseInt(hex.substr(i, 2), 16));
    }
    return new Uint8Array(bytes);
  }

  
}


