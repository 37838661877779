import { Component, OnInit, ViewChild } from "@angular/core";
import { MatTableDataSource} from "@angular/material/table";
import { Sort } from "@angular/material/sort";
import { TableSortService } from "../../services/table-sort.services";
import { MatPaginator } from "@angular/material/paginator";
import { FormBuilder,FormControl,FormGroup, Validators } from "@angular/forms";
import { ISearchPermanent } from "src/app/interfaces/search/searchpermanent";
import {SearchService} from '../../services/search/search.service';
import { ISearchInput } from "src/app/interfaces/search/searchinput";
import { IReferenceData } from "src/app/interfaces/admin/referencedata";
import { IStatus } from "src/app/interfaces/admin/status";
import { ICategoryModel } from "src/app/interfaces/admin/categorymodel";
import { IDOATypeModel } from "src/app/interfaces/admin/doatypemodel";
import { CommonService } from '../../services/common/common.service';
import { Router } from '@angular/router';

@Component({
  selector: 'approveandrevokepermanentdoa',
  templateUrl: './approveandrevokepermanentdoa.component.html',
  styleUrls: ['./approveandrevokepermanentdoa.component.css']
})
export class ApproveandRevokePermanentDOAComponent implements OnInit {
  title = "Approve and Revoke Permanent DOA";  
  dataSourceLoading: boolean = false; 
  listingsVisible: boolean = true; 
  public searchInput: ISearchInput;
  searchForm: FormGroup;   
  referenceData:any;
  dataSource: MatTableDataSource<ISearchPermanent>;
  originalDataSource: MatTableDataSource<ISearchPermanent>;
  lstCategory: ICategoryModel[];
  lstDOAType:IDOATypeModel[];
  lstDOATypeFilter:IDOATypeModel[];
  businessUnit:string;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  
  public displayedColumns: string[] = [
    "employeeName",
    "empEmail",
    "typeName",
    "amount",
    "effectiveDate",
    "status",
    "grantorEmail",
    "supervisorEmail",
    "changeBy"   
    ]; 

  constructor(
    private tableSortService: TableSortService, 
    private searchDOADataService: SearchService,     
    private fb: FormBuilder ,
    private commonService: CommonService,
    private router: Router     
  ) {    
  }
  ngOnInit(): void {  
   this.businessUnit = this.commonService.getCurrentBusinessUnit();    
            
    this.searchForm = this.fb.group({
    statusDesc: 'All',
    authorityLine: 'All',
    authorityLineCode: 0,
    department: '',
    grantorEmail: ['',[Validators.email]],
    categoryName: 'All',
    categoryCode: 0,
    employeeEmail: ['',[Validators.email]],
    supervisorEmail:['',[Validators.email]],
    statusAdmin:0
    });
    this.searchInput = this.searchForm.value;
    this.referenceData = this.commonService.getReferenceData();
      if (this.referenceData) {
       this.lstCategory = this.referenceData.categoryList;
       this.lstDOAType = this.referenceData.doaTypeList;
       this.lstDOATypeFilter = this.referenceData.doaTypeList;     
      } 
    }
    // redirectToPermanentDOA(entity) {
    //   var param = {
    //    requestId: 0,
    //    typeCode: 0,
    //    empCAI: entity.grantorEmail,
    //    effectiveDate:entity.effectiveDate ,
    //    action: 'View'
    //    }
    //    this.router.navigate(['/DOA/PermanentDOA']);   
    //  }
 
 announceSortChange(event: Sort) {
  this.dataSource.data = this.tableSortService.sortDataSource(
    this.originalDataSource.data,
    event
  );
}
clearFilters() {
  this.searchForm.reset({
    statusDesc: 'All',
    categoryName: 'All',
    authorityLine: 'All',
    authorityLineCode: 0,
    department: '',
    grantorEmail: '',   
    categoryCode: 0,
    employeeEmail: '',
    supervisorEmail:'',
    statusAdmin:0 
  });
  this.searchInput=this.searchForm.value;
  this.dataSource ? this.dataSource.data = [] : null;
  this.listingsVisible = true;    
}
onSearch(){  
  this.dataSourceLoading = true; 
  this.searchInput.employeeEmail= this.searchForm.get("employeeEmail").value;
  this.searchInput.grantorEmail= this.searchForm.get("grantorEmail").value;
  this.searchInput.supervisorEmail= this.searchForm.get("supervisorEmail").value;
  this.searchInput.businessUnit= this.businessUnit;
  this.searchDOADataService
    .searchApproveRevokeDOA(this.searchInput)
    .subscribe((data:any) => { 
      this.dataSourceLoading = false;       
      if(data!=null && data.length>0) 
      {
        this.listingsVisible = false;       
        this.dataSource = new MatTableDataSource(data);
        this.originalDataSource = new MatTableDataSource(data);
        this.dataSource.paginator = this.paginator; 
      }    
      else{
        this.listingsVisible = true;
        this.dataSourceLoading = false;
        this.dataSource.data=[];
      }      
    }); 
}
changeCategory(category) {
  if (category === 'All') {
      this.searchInput.categoryName = category;
      this.searchInput.categoryCode = 0;
      this.lstDOAType = this.lstDOATypeFilter;
  } else {
      const selectedCategory = this.lstCategory.find(cat => cat.categoryName === category);
      if (selectedCategory) {
          this.searchInput.categoryName = selectedCategory.categoryName;
          this.searchInput.categoryCode = selectedCategory.id;
          this.lstDOAType = this.lstDOATypeFilter.filter(item => item.categoryId === selectedCategory.id);
      }
  }
  this.searchInput.authorityLine = 'All';
  this.searchInput.authorityLineCode = 0;
}
changeAuthorityLine(doaType) {
  if (doaType == 'All') {
      this.searchInput.authorityLine = doaType;
      this.searchInput.authorityLineCode = 0;
  }
  else {   
    this.searchInput.authorityLine = doaType;
    this.searchInput.authorityLineCode = this.lstDOATypeFilter.find(cat => cat.typeName === doaType).id;
  }
}
viewPendingReq = function (row) {  
  var params = {
      requestId: row.requestID,
      typeCode: 0,
      empEmail: null,
      action: ''
  };
  if (row.status == 'Submitted') {
      params.action = 'Approve';
  }
  else if (row.status == 'Approved') {
      params.action = 'Reconcile';           
  }
  else if (row.status == 'Revoked') {
      params.action = 'View';
  }
  else if (row.status == 'Rejected') {
      params.action = 'View';
  }  
  this.commonService.params=params;
  this.router.navigate(['/Admin/PermanentDOAAdmin']);
}
openReq = function (row) {
  var params = {
    requestId:0,
    typeCode:row.typeCodeId,
    empEmail:row.empEmail,
    action:'Approve'
  }
  this.commonService.params=params;
  this.router.navigate(['/Admin/PermanentDOAAdmin']);
}
onStatusChange(status) { 
  this.searchInput.statusDesc=status
  if(status == 'All'){
   this.searchInput.statusAdmin = 0;   
  }  
  if (status == "Submitted") {
    this.searchInput.statusAdmin = 1;
  }
  if (status == "Approved") {
    this.searchInput.statusAdmin = 2;
  }
  if (status == "Rejected") {
      this.searchInput.statusAdmin = 3;
  }
  if (status == "Revoked") {
      this.searchInput.statusAdmin = 4;
  }  
}
// applyFilter(filterValue: string, column: string) {
//   this.dataSource.filterPredicate = (data: ISearchPermanent, filter: string) => {
//     const transformedFilter = JSON.parse(filter);
//     const dataValue = data[column] ? String(data[column]).toLowerCase() : '';
//     return dataValue.includes(transformedFilter[column].toLowerCase());
//   };
//   const filterObject = { [column]: filterValue };
//   this.dataSource.filter = JSON.stringify(filterObject);
// }
applyFilter(filterValue: string, column: string) {
  let currentFilter = this.dataSource.filter ? JSON.parse(this.dataSource.filter) : {};
  currentFilter[column] = filterValue.toLowerCase();
  this.dataSource.filter = JSON.stringify(currentFilter);
  this.dataSource.filterPredicate = (data: ISearchPermanent, filter: string) => {
      const transformedFilter = JSON.parse(filter);
      return Object.keys(transformedFilter).every(key => 
          data[key].toLowerCase().includes(transformedFilter[key].toLowerCase())
      );
  };
  this.dataSource.filter = JSON.stringify(currentFilter);
}
}
