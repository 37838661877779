import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CalAngularService } from '@cvx/cal-angular';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/auth.service';
import { DoaInfoModalComponent } from 'src/app/doa-info-modal/doa-info-modal.component';
import { EncryptionService } from 'src/app/encryption.service';
import { IDelegationOfAuthority } from 'src/app/interfaces/admin/doa';
import { IBuCollection } from 'src/app/interfaces/admin/homeUrlsList';
import { IReferenceData } from 'src/app/interfaces/admin/referencedata';
import { environment } from 'src/environments/environment';
const apiUrl = environment.apiUrl;

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  params: any;
  tempRedelegationId: number;
  isNewRequest: boolean;
  isUpdateRequest: boolean;

  constructor(
    private authService: CalAngularService,
    private http: HttpClient,
    private dialog: MatDialog,
    private encriptionService : EncryptionService
  ) {}
  openModal(messageTitle: string, messageDesc: string): void {
    this.dialog.open(DoaInfoModalComponent, {
      data: { title: messageTitle, modalMessage: messageDesc },

      width: 'auto',
      // maxWidth: '80%',
      // minWidth: '50%',
      position: { top: '1' },
    });
  }

  closeModal(): void {
    this.dialog.closeAll();
  }
  public isRoleAdmin(): Observable<boolean> {
    return new Observable<boolean>((observer) => {
      this.authService.isInGroup('ConsolidatedDOAAdmins').subscribe((data) => {
        observer.next(data);
        observer.complete();
      });
    });
  }
  isRoleAuditor(): Observable<boolean> {
    return new Observable<boolean>((observer) => {
      this.authService
        .isInGroup('ConsolidatedDOAAuditors')
        .subscribe((data) => {
          observer.next(data);
          observer.complete();
        });
    });
  }

  public getHomeResources(currentBU: string): Observable<IBuCollection> {
    return this.http.get<IBuCollection>(
      `${apiUrl}/HomeResources?currentBU=${currentBU}`
    );
  }
  public getCurrentBusinessUnit(): string {
    return environment.BusinessUnit;    
  }
  
  public getLoggedInUserEmail(): string {
    return this.authService.cvxClaimsPrincipal.userName;    
  }
  public getEmployeeInfo(): any {
    var storedData = localStorage.getItem('EmployeeInfo');
    let decryptedData: any = null;
    if (storedData) {
        decryptedData = this.encriptionService.decryptData(storedData);
    }
    return decryptedData ? decryptedData : null;
}

  public getReferenceData(): any {
    var storedData = localStorage.getItem('ReferenceData');
    let decryptedData: any = null;
    if (storedData) {
        decryptedData = this.encriptionService.decryptData(storedData);
    }
    return decryptedData ? decryptedData.referenceData : null;
  }
}
