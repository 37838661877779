import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {AdminService} from '../../../services/admin/admin.service';
@Component({
  selector: 'app-remove-dialog',
  standalone: true,
  imports: [],
  templateUrl: './remove-dialog.component.html',
  styleUrl: './remove-dialog.component.css'
})
export class RemoveDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<RemoveDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private adminService: AdminService 
  ) {}

  onCancel(): void {
    this.dialogRef.close(false);
  }

  onConfirm(): void {
   // console.log("data:",this.data);
    this.dialogRef.close(true); // Indicate success
    
  }
}
