import { Component, OnInit } from '@angular/core';
import { CalAngularService } from '@cvx/cal-angular';
import {
  NextPageService,
  NavigationLocation,
  PageLayout,
  SearchType,
  SliderType,
} from '@cvx/nextpage';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from '../environments/environment';
import { AuthService } from './auth.service';
import { EncryptionService } from './encryption.service';
import { IReferenceData } from './interfaces/admin/referencedata';
import { IDelegationOfAuthority } from './interfaces/admin/doa';
import { HttpClient } from '@angular/common/http';

const apiUrl = environment.apiUrl;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styles: [],
})
export class AppComponent implements OnInit {
  isLoading: boolean = false;
  NavigationLocation = NavigationLocation;
  PageLayout = PageLayout;
  SearchType = SearchType;
  SliderType = SliderType;
  loggedUsername: string = '';
  loggedUserEmail: string = '';
  loggedUserCai: string = '';
  jobTitle: string = '';
  office: string = '';
  personRelationshipType: string = '';
  scope: any;
  referenceData: IReferenceData[] = [];
  employeeInfo: IDelegationOfAuthority | null = null;
  businessUnit: string = '';

  constructor(
    private NP: NextPageService,
    private authService: CalAngularService,
    private http: HttpClient,
    private localAuthService: AuthService,
    private encryptionService: EncryptionService
  ) {
    this.NP.options.sitename = 'SASBU DOA';
  }

  ngOnInit() {
    if (this.authService.isUserSignedIn()) {
      this.loggedUsername = this.authService.cvxClaimsPrincipal.name;
      this.loggedUserEmail = this.authService.cvxClaimsPrincipal.userName;
      this.loggedUserCai = '';
      this.jobTitle = this.authService.cvxClaimsPrincipal.jobTitle;
      this.office = this.authService.cvxClaimsPrincipal.office;
      this.personRelationshipType =
        this.authService.cvxClaimsPrincipal.personRelationshipType;

      this.isLoading = true;
      this.getReferenceData(this.loggedUserEmail).subscribe((data) => {
        this.referenceData = data;
        const encryptedData = this.encryptionService.encryptData(
          this.referenceData
        );
        localStorage.setItem('ReferenceData', encryptedData);

        this.getEmployeeInfo(this.loggedUserEmail).subscribe((data) => {
          this.employeeInfo = data;
          this.businessUnit = environment.BusinessUnit;

          this.getEmployeeAdditionalRoles(this.loggedUserEmail).subscribe(
            (data) => {
              this.employeeInfo.roles = data.roles || [];
              if (this.employeeInfo.hasAdminRole === true) {
                this.employeeInfo.roles.push('Admin');
              }
              if (this.employeeInfo.hasAuditorRole === true) {
                this.employeeInfo.roles.push('Auditor');
              }

              const encryptedEmployeeInfo = this.encryptionService.encryptData(
                this.employeeInfo
              );
              localStorage.setItem('EmployeeInfo', encryptedEmployeeInfo);
              this.isLoading = false;
              const storedEmployeeData = localStorage.getItem('EmployeeInfo');
              if (storedEmployeeData) {
                const decryptedEmployeeInfo =
                  this.encryptionService.decryptData(storedEmployeeData);
              }

              // Call AuthService method
              const roles = this.localAuthService.getEmployeeRoles();
            }
          );
        });
      });
    } else {
      console.log('USER NOT LOGGED IN');
      this.isLoading = false;
    }
  }

  getReferenceData(email: string): Observable<IReferenceData[]> {
    return this.http.get<IReferenceData[]>(
      `${apiUrl}/ReferenceData?email=${email}`
    );
  }

  getEmployeeInfo(email: string): Observable<IDelegationOfAuthority> {
    return this.http.get<IDelegationOfAuthority>(
      `${apiUrl}/DelegationOfAuthority?email=${email}`
    );
  }

  getEmployeeAdditionalRoles(
    email: string
  ): Observable<IDelegationOfAuthority> {
    return this.http.get<IDelegationOfAuthority>(
      `${apiUrl}/DelegationOfAuthority/GetBUSpecificRoles?email=${email}&currentBU=${this.businessUnit}`
    );
  }
}
