import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { IReferenceData } from "src/app/interfaces/admin/referencedata";
import { CalAngularService } from '@cvx/cal-angular';
import { CommonService } from '../services/common/common.service';
import { IBuCollection, IHomeUrlsList } from '../interfaces/admin/homeUrlsList';
import { Router } from '@angular/router';
import { EncryptionService } from '../encryption.service';

const apiUrl = environment.apiUrl;

@Component({
  selector: 'home',
  templateUrl: 'home.component.html',
  styleUrls: ["home.component.css"],
  imports: [CommonModule, HttpClientModule, BrowserModule]
})

export class HomeComponent implements OnInit {
  sections = {
    resources: true,
    links: true
  }
  urls: any = {};
  signIn: boolean = true;
 referenceData: IReferenceData;
  homeResources:IHomeUrlsList ={}as IHomeUrlsList;
  buCollection:IBuCollection;
  businessUnit: string = '';
constructor(private http: HttpClient, private authService: CalAngularService,private commonService:CommonService,private router: Router,private encryptionService:EncryptionService) { }

  ngOnInit(): void {
    if (this.authService.isUserSignedIn()) {
      this.referenceData = this.commonService.getReferenceData();
      this.businessUnit = this.commonService.getCurrentBusinessUnit();    
    }
    
    this.commonService.getHomeResources(this.businessUnit).subscribe(data => {    
     
      this.buCollection=data;
      this.homeResources = this.buCollection.sasbu;
    });

  }
  closeSection(section: string) {
    this.sections[section] = !this.sections[section];
  }
  navigateToHome(): void {
    this.router.navigate(['Home']);
  }
}

