import { Location } from '@angular/common';
import { Component, OnInit, Renderer2 } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CalAngularService } from '@cvx/cal-angular';
import { CommonService } from 'src/app/services/common/common.service';
import { DelegationOfAuthorityService } from 'src/app/services/doa/delegationOfAuthority.service';
import { AppComponent } from '../../app.component';
import { IPermanentdoa, IRevoke } from '../../interfaces/doa/permanentdoa';
import { ConfirmPopupComponent } from './confirm-popup/confirm-popup-component';
import { ReviewDialogComponent } from './review-dialog/review-dialog.component';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'permanentdoa',
  templateUrl: 'permanentdoa.component.html',
  styleUrls: ['permanentdoa.component.css']
})
export class PermanentDOAComponent implements OnInit {
  title = "New Permanent DOA";
  selectedfile: File = null;
  selectedOption = '1';
  email: any;
  emailSearched: boolean = false;
  mydata: any;
  doaForm: FormGroup;
  referenceData: any;
  DoaTypeList: any;
  CategoryList: any;
  Amount: any;
  selectedFile: File | null = null;
  selectedAmounts: { [key: string]: number } = {};
  enabledDoatypeIds: Set<number> = new Set();
  enabledCatCodes: Set<number> = new Set();
  maxAmounts: { [key: number]: number } = {};
  minDate: string;
  formattedDate: string | null = null;
  masterData: any;
  grantorMasterData: any;
  loggedUserEmail: string;
  grantorEmail: string;
  requestId: number;
  reqTypeCode: number;
  reqEmpCAI: string;
  reqEffectiveDate: Date;
  userCAI: string;
  action: string;
  empData: any;
  employeeData: any;
  isEditMode = false;
  ELEMENT_DATA: IPermanentdoa[] = [];
  currentBU: string;
  emailIds: string;

  permanentrequestId: number;
  reason: string;
  grantorAmount: any;
  EmpEmail: string;
  TypeCode: number;
  approve: any;
  modalMessage: string;
  selectedEmail: string;
  ReplacedBy_Email: string;
  PermanentDOAEmail: string;
  dataSourceLoading: boolean = false;
  dataSource1Loading: boolean = false;
  dataSource2Loading: boolean = false;
  isAuditor: boolean;
  isAdmin: boolean;


  constructor(private permanentdoaService: DelegationOfAuthorityService,
    private appComponent: AppComponent,
    private fb: FormBuilder,
    private authService: CalAngularService,
    private router: Router,
    private commonService: CommonService,
    private location: Location,
    private renderer: Renderer2,
    private dialog: MatDialog,
    private route: ActivatedRoute,
  ) {

    this.doaForm = this.fb.group({
      selectedOption: ['1', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      effectiveDate: ['', Validators.required],
    });
  }


  ngOnInit(): void {
    this.initializeData();

    if (this.action == 'New') {
      this.loggedUserEmail = this.authService.cvxClaimsPrincipal.email;
      this.permanentdoaService.getCategoryDOA(this.loggedUserEmail).subscribe((response) => {
        this.mydata = response;
        this.empData = response.empoyeeInfo;
        this.CategoryList = response.referenceData.categoryList
        this.DoaTypeList = response.referenceData.doaTypeList;
        this.Amount = response.referenceData.amountRangeList;
      });
    }
    //added to display latest permanent doa of the selected user CAI - 1 April 2019
    if (this.userCAI != undefined) {
      this.dataSource2Loading = true;
      this.isEditMode = true;
      this.permanentdoaService.getCategoryDOA(this.userCAI).subscribe((response) => {
        this.processCategoryDOAData(response);
      });
      this.permanentdoaService.getMasterData(this.currentBU, this.userCAI).subscribe((response: any) => {
        this.dataSource2Loading = false;
        this.processMasterData(response.filter(x => x.isActive === true));
      });
      this.action = "View";
    }
    //Bind data for Pending requests coming from MyDelegation report page
    else if (this.requestId != 0) {
      this.dataSource2Loading = true;
      this.isEditMode = true;
      this.permanentdoaService.getDoaDataByRequestId(this.requestId, this.currentBU).subscribe((response) => {
        this.masterData = response;

        if (this.masterData != null) {
          if (this.masterData.replacedBy_Email != null) {
            this.ReplacedBy_Email = this.masterData.replacedBy_Email;
          }
          this.permanentdoaService.getCategoryDOA(this.masterData.empEmail).subscribe((response) => {
            this.dataSource2Loading = false;
            this.processCategoryDOAData(response);
          });
          this.reqEffectiveDate = this.masterData.effectiveDate;
          this.grantorEmail = this.masterData.grantorEmail;
          this.enabledDoatypeIds.add(this.masterData.typeCodeId);
          this.enabledCatCodes.add(this.masterData.catCodeId);
          this.maxAmounts[this.masterData.typeCodeId] = this.masterData.amount;
          this.selectedAmounts['selectedAmount' + this.masterData.typeCodeId] = this.masterData.amount;
        }
      });
    }
    //Bind data for Approved/Revoked requests coming from MyDelegation report page
    else if (this.reqTypeCode != 0 && this.reqEmpCAI != null && this.action == 'Reconcile') {
      this.dataSource2Loading = true;
      this.isEditMode = true;
      this.permanentdoaService.getDoaMasterByEmpEmailTypeCode(this.reqEmpCAI, this.reqTypeCode, this.currentBU).subscribe((response) => {
        this.masterData = response;
        if (this.masterData != null) {
          this.permanentdoaService.getCategoryDOA(this.masterData.empEmail).subscribe((response) => {
            this.processCategoryDOAData(response);
          });
          this.permanentdoaService.getMasterData(this.currentBU, this.masterData.empEmail).subscribe((response) => {
            this.dataSource2Loading = false;
            this.reqEffectiveDate = this.masterData.effectiveDate;
            this.grantorEmail = this.masterData.grantorEmail;
            this.enabledDoatypeIds.add(this.masterData.typeCodeId);
            this.enabledCatCodes.add(this.masterData.catCodeId);
            this.maxAmounts[this.masterData.typeCodeId] = this.masterData.amount;
            this.selectedAmounts['selectedAmount' + this.masterData.typeCodeId] = this.masterData.amount;
          });
          this.permanentdoaService.getDoaMasterByEmpEmailTypeCode(this.masterData.grantorEmail, this.reqTypeCode, this.currentBU).subscribe((res) => {
            this.dataSource2Loading = false;
            this.grantorMasterData = res;
            if (this.grantorMasterData != null)
              this.grantorAmount = this.masterData.amount;
          });
        }
      });
    }
    //Bind data for Approved requests coming from Search Permanent DOA page
    else if (this.reqTypeCode != 0 && this.reqEmpCAI != null && this.action == 'View') {
      this.dataSource2Loading = true;
      this.isEditMode = true;
      this.permanentdoaService.getCategoryDOA(this.reqEmpCAI).subscribe((response) => {
        this.processCategoryDOAData(response);
      });
      this.permanentdoaService.getMasterData(this.currentBU, this.reqEmpCAI).subscribe((response: any) => {
        this.dataSource2Loading = false;
        this.processMasterData(response.filter(x => x.isActive === true));
      });
    }
    //Bind data for view doa details from profile page 
    else if (this.requestId == 0 && this.reqTypeCode == 0 && this.reqEmpCAI != null) {
      this.dataSource2Loading = true;
      this.isEditMode = true;
      this.loggedUserEmail = this.authService.cvxClaimsPrincipal.email;
      this.permanentdoaService.getCategoryDOA(this.loggedUserEmail).subscribe((response) => {
        this.processCategoryDOAData(response);
      });
      this.permanentdoaService.getMasterData(this.currentBU, this.loggedUserEmail).subscribe((response: any) => {
        this.dataSource2Loading = false;
        this.processMasterData(response.filter(x => x.isActive === true && x.grantorEmail === this.reqEmpCAI));
      });
    }
    else {
      this.dataSource2Loading = false;
      console.log();
    }
    this.route.queryParams.subscribe(params => {
      const userCAI = params['userCAI'];
      const grantorCAI = params['grantorCAI'];
      this.handleRedirection(userCAI, grantorCAI);
    });
  }
  handleRedirection(userCAI: string, grantorCAI: string) {
    const currentUser = this.authService.cvxClaimsPrincipal.email;
    if (currentUser === userCAI) {
      this.router.navigate(['Search/PermanentDOA']);
    } else if (currentUser === grantorCAI) {
      this.router.navigate(['Report/MyDelegation']);
    }
    //  else{
    //   this.router.navigate(['UnauthorizePage']);
    //  }
  }
  initializeData() {
    if (this.commonService.params != null) {
      this.requestId = this.commonService.params.requestId;
      this.action = this.commonService.params.action;
      this.reqTypeCode = this.commonService.params.typeCode;
      this.reqEmpCAI = this.commonService.params.empEmail;
      this.userCAI = this.commonService.params.userEmail;
      this.commonService.params = null;
    } else {
      this.requestId = 0;
      this.action = 'New';
      this.reqTypeCode = 0;
      this.reqEmpCAI = null;
      this.userCAI = null;
      this.commonService.params = null;
    }
    this.commonService.isRoleAdmin().subscribe((response) => {
      this.isAdmin = response;
    });
    this.commonService.isRoleAuditor().subscribe((response) => {
      this.isAuditor = response;
    });
    this.loggedUserEmail = this.authService.cvxClaimsPrincipal.email;
    var storedData =this.commonService.getEmployeeInfo();
    if (storedData) {
      this.currentBU = storedData.businessUnit;
    }
    const today = new Date().toISOString().split('T')[0];
    this.minDate = today;
    this.doaForm.patchValue({ effectiveDate: today });
  }
  processCategoryDOAData(response: any) {
    this.empData = response.empoyeeInfo;
    this.CategoryList = response.referenceData.categoryList;
    this.DoaTypeList = response.referenceData.doaTypeList;
    this.Amount = response.referenceData.amountRangeList;
  }

  processMasterData(response: any) {
    this.masterData = response;
    if (this.masterData != null) {
      this.masterData.forEach((data: any) => {
        this.reqEffectiveDate = data.effectiveDate;
        this.grantorEmail = data.grantorEmail;
        this.enabledDoatypeIds.add(data.typeCodeId);
        this.enabledCatCodes.add(data.catCodeId);
        this.maxAmounts[data.typeCodeId] = data.amount;
        this.selectedAmounts['selectedAmount' + data.typeCodeId] = data.amount;
      });
    }
  }
  goBack(): void {
    this.location.back();
  }
  MyDelegationsReport() {
    this.router.navigate(['Report/MyDelegation']);
  }
  editmode() {
    this.isEditMode = false;
  }
  changeAmount(amount: any, doatypeId: any): void {
    if (doatypeId == 3 && amount) {
      this.showSuccessModal('Selecting this authority line will subject to verification of requested amount with Ariba system.');
    }
  }
  showSuccessModal(msg: string, callback?: () => void): void {
    this.modalMessage = msg;
    const modal = document.getElementById('successModal') as HTMLElement;
    const okBtn = document.getElementById('closeSuccessModal') as HTMLElement;

    modal.style.display = 'block';

    okBtn.onclick = () => {
      modal.style.display = 'none';
      if (callback) {
        callback();
      }
    };

    window.onclick = (event) => {
      if (event.target === modal) {
        modal.style.display = 'none';
        if (callback) {
          callback();
        }
      }
    };
  }
  printPage() {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      data: { message: "Please print the contents in Landscape mode for better visibility. Change the settings of orientation using preferences button of print dialog. Click Yes to print." }

    });

    dialogRef.afterClosed().subscribe(confirm => {
      if (confirm) {
        setTimeout(() => {
          this.toggleElementsDisplay('none');
          window.print();
          setTimeout(() => {
            this.toggleElementsDisplay('block');
          }, 200);
        }, 200);
      }
    });
  }
  private toggleElementsDisplay(display: string): void {
    const elements = ['buttonDiv', 'topMenu', 'topHeader', 'menuBar', 'footerBar'];
    elements.forEach(id => {
      const element = document.getElementById(id);
      if (element) {
        this.renderer.setStyle(element, 'display', display);
      }
    });
  }
  //Grantor retains authority line already allocated to user
  retainAuthority() {
    this.dataSource1Loading = true;
    this.EmpEmail = this.reqEmpCAI;
    this.TypeCode = this.reqTypeCode;
    this.requestId = this.masterData.id;
    this.permanentdoaService.retainAuthority(this.requestId, this.EmpEmail, this.TypeCode, this.currentBU, this.loggedUserEmail, this.selectedFile).subscribe({
      next: (response) => {
        this.dataSource1Loading = false;
        console.log('Authority line retained successfully', response);
        this.showSuccessModal('Authority line retained successfully', () => {
          this.router.navigate(['Report/MyDelegation']);
        });
      },
      error: (error) => {
        this.dataSource1Loading = false;
        console.error('Error sending data', error);
      }
    });

  }
  //Grantor revokes authority line already allocated to user
  revokeAuthority() {
    this.dataSource1Loading = true;
    this.loggedUserEmail = this.authService.cvxClaimsPrincipal.email;
    const revoke: IRevoke = {
      Email: this.masterData.empEmail,
      TypeCodeId: this.masterData.typeCodeId,
      CatCodeId: this.masterData.catCodeId,
      EffectiveDate: this.masterData.effectiveDate,
      BusinessUnit: this.currentBU,
      LoggedInUserEmail: this.loggedUserEmail,
      RequestID: this.requestId
    }
    this.permanentdoaService.revokeAuthority(revoke, this.selectedFile).subscribe({
      next: (response) => {
        this.dataSource1Loading = false;
        console.log('Authority line revoked successfully', response);
        this.showSuccessModal('Authority line revoked successfully', () => {
          this.router.navigate(['Report/MyDelegation']);
        });
      },
      error: (error) => {
        this.dataSource1Loading = false;
        console.error('Error sending data', error);
      }
    });

  }
  //Grantor rejects request already submitted by user
  rejectRequest() {
    this.dataSource1Loading = true;
    const dialogRef = this.dialog.open(ReviewDialogComponent, {
      width: '250px',
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed with review:', result);
      this.reason = result;
      if (this.reason) {
        this.permanentrequestId = this.requestId,
          this.permanentdoaService.rejectRequest(this.permanentrequestId, this.reason, this.currentBU, this.loggedUserEmail, this.selectedFile).subscribe({
            next: (response) => {
              this.dataSource1Loading = false;
              console.log('Permanent DOA request has been successfully rejected.', response);
              this.showSuccessModal('Permanent DOA request has been successfully rejected.', () => {
                this.router.navigate(['Report/MyDelegation']);
              });
            },
            error: (error) => {
              this.dataSource1Loading = false;
              console.error('Error sending data', error);
            }
          });
      }
    });
  }

  //Grantor approves request already submitted by User
  approveRequest(actionId: number) {
    this.dataSource1Loading = true;
    this.loggedUserEmail = this.authService.cvxClaimsPrincipal.email;
    this.DoaTypeList.forEach(doatype => {
      const selectedAmount = this.selectedAmounts['selectedAmount' + doatype.id];
      if (doatype.typeDelegated === 'Y' && selectedAmount) {
        this.approve = {
          "id": this.masterData.id,
          "empEmail": this.masterData.empEmail,
          "typeCodeId": this.masterData.typeCodeId,
          "catCodeId": this.masterData.catCodeId,
          amount: selectedAmount.toString(),
          "grantorEmail": this.masterData.grantorEmail,
          "supervisorEmail": this.masterData.supervisorEmail,
          "effectiveDate": this.masterData.effectiveDate,
          "isActive": true,
          "loggedInUserEmail": this.loggedUserEmail,
          "businessUnit": this.currentBU,
          "noteCode": doatype.noteCode
        };
      }
    })

    if (this.approve) {
      if (actionId == 1) {
        this.permanentdoaService.approveRequest(this.approve, this.currentBU, this.requestId, this.selectedfile).subscribe({
          next: (response) => {
            this.dataSource1Loading = false;
            console.log('Permanent DOA request has been successfully approved.', response);
            this.showSuccessModal('Permanent DOA request has been successfully approved.', () => {
              this.router.navigate(['Report/MyDelegation']);
            });
          },
          error: (error) => {
            this.dataSource1Loading = false;
            console.error('Error sending data', error);
          }
        });
      }
      else if (actionId == 2) {
        this.permanentdoaService.modifyApproveRequest(this.approve, this.currentBU).subscribe({
          next: (response) => {
            this.dataSource1Loading = false;
            console.log('Permanent DOA request has been successfully approved.', response);
            this.showSuccessModal('Permanent DOA request has been successfully updated and approved.', () => {
              this.router.navigate(['Report/MyDelegation']);
            });
          },
          error: (error) => {
            this.dataSource1Loading = false;
            console.error('Error sending data', error);
          }
        });
      }

    }
    else {
      this.dataSource1Loading = false;
      this.showSuccessModal('Request cannot be approved for empty DOA amount.');
    }
  }

  SendData(): void {
    this.dataSource1Loading = true;
    this.ELEMENT_DATA = [];
    this.DoaTypeList.forEach(doatype => {
      const selectedAmount = this.selectedAmounts['selectedAmount' + doatype.id];

      if (doatype.typeDelegated === 'Y' && selectedAmount) {
        const newElement: IPermanentdoa = {
          Id: 0,
          EmpEmail: this.empData.emailAddress,
          EmpName: this.empData.name,
          GrantorEmail: this.doaForm.controls['email'].value,
          SupervisorEmail: this.empData.supervisorEmail,
          TypeCodeId: doatype.id,
          CatCodeId: doatype.categoryId,
          Amount: selectedAmount,
          EffectiveDate: this.doaForm.controls['effectiveDate'].value,
          StatusCodeId: 1,
          ApprovedDate: null,
          RejectionReason: null,
          ReplacedBy_Email: this.doaForm.controls['selectedOption'].value === '3' ? this.doaForm.controls['email'].value : null,
          Flag: 0,
          BusinessUnit: this.empData.businessUnit,
          LoggedInUserEmail: this.empData.emailAddress,
          NoteCode: doatype.noteCode
        };

        this.ELEMENT_DATA.push(newElement);
      }
    });
    if (this.ELEMENT_DATA.length > 0) {
      this.permanentdoaService.submitPermanentDOAReq(this.ELEMENT_DATA, this.currentBU).subscribe({
        next: (response) => {
          this.dataSource1Loading = false;
          console.log('Data sent successfully', response);
          this.showSuccessModal('Your permanent DOA request has been successfully created and submitted to approver for further processing.', () => {
            this.router.navigate(['Home']);
          });
        },
        error: (error) => {
          this.dataSource1Loading = false;
          console.error('Error sending data', error);
        }
      });
    }
    else {
      this.dataSource1Loading = false;
      this.showSuccessModal('Permanent DOA request cannot be submitted for empty DOA amount.');
    }
  }
  resetForm(): void {
    this.emailSearched = false;
    this.doaForm.reset();
    this.doaForm.controls['selectedOption'].setValue('1');
    this.doaForm.controls['email'].markAsTouched();
    this.enabledDoatypeIds.clear();
    this.enabledCatCodes.clear();
    this.selectedAmounts = {};
    this.isEditMode = true;
    this.ReplacedBy_Email = null;
  }

  onSearch() {
    this.dataSourceLoading = true;
    this.enabledDoatypeIds.clear();
    this.enabledCatCodes.clear();
    this.selectedAmounts = {};
    this.PermanentDOAEmail = this.doaForm.get('email')?.value;
    if (this.doaForm.get('email')?.value.toLowerCase() === this.authService.cvxClaimsPrincipal.email.toLowerCase()) {
      this.dataSourceLoading = false;
      this.showSuccessModal('You can not be Grantor for yourself');
    }
    else {
      this.dataSourceLoading = false;
      if (this.doaForm.controls['email'].invalid) {
        this.doaForm.controls['email'].markAsTouched();
        return;
      }
      this.emailSearched = true;
      this.permanentdoaService.getCategoryDOA(this.doaForm.get('email')?.value).subscribe((response) => {
        this.dataSourceLoading = false;
        this.employeeData = response.empoyeeInfo;
        if (!this.employeeData) {
          this.dataSourceLoading = false;
          this.showSuccessModal('Employee information not found')
        }
        this.CategoryList = response.referenceData.categoryList
        this.DoaTypeList = response.referenceData.doaTypeList;
        this.Amount = response.referenceData.amountRangeList;
      });

      this.permanentdoaService.getMasterData(this.currentBU, this.doaForm.get('email')?.value).subscribe((response: any) => {
        this.dataSourceLoading = false;
        this.masterData = response.filter(x => x.isActive === true);
        if (this.masterData.length === 0) {
          this.dataSourceLoading = false;
          this.showSuccessModal('Permanent DOA information is not present for entered Email.')
        }
        if(this.masterData.length>0){
        this.grantorEmail = this.masterData[0].grantorEmail;
        this.masterData.forEach((data: any) => {
          this.enabledDoatypeIds.add(data.typeCodeId);
          this.enabledCatCodes.add(data.catCodeId);
          this.maxAmounts[data.typeCodeId] = parseInt(data.amount, 10);
        });
      }
      });
    }
  }
  getAmountRangeForDoatype(doatypeId: any): any[] {
    const maxAmount = this.maxAmounts[doatypeId];
    return this.Amount.filter((amount: any) => amount.rangeAmount <= maxAmount);
  }
  isDropdownDisabled(doatypeId: number, catCode: number): boolean {
    return !(this.enabledDoatypeIds.has(doatypeId) && this.enabledCatCodes.has(catCode));
  }
  searchDelegator() {
    var PermanentDOACai = this.doaForm.get('email')?.value;
    if (PermanentDOACai.toUpperCase().trim() == this.loggedUserEmail) {
      this.showSuccessModal('You can not be Grantor for yourself')
      this.permanentdoaService.getCategoryDOA(PermanentDOACai).subscribe((response) => {
        if (response == null || response == "") {
          this.showSuccessModal('employee information not found')
        }
        else {
          if (response.doaMasterData.length > 0) {
            var doaGrantoCAICopyReplace = this.UniqueArraybyKeyName(response.doaMasterData, "GrantorCAI");
          }
          else {
            this.showSuccessModal('Permanent DOA information is not present for entered CAI.')
          }
        }
      });

    }
  }
  UniqueArraybyKeyName<T>(collection: T[], keyname: keyof T) {
    const keys: any[] = [];
    collection.forEach(item => {
      const key = item[keyname];
      if (!keys.includes(key)) {
        keys.push(key);
      }
    });
  }
}




